import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CreateReasonModel, ResponseModel, UpdateReasonModel } from '../Model/ResponseModel';
import { AppointmentStatusService } from '../services/appointment-status.service';
import { DhqdepartmentsService } from '../services/dhqdepartments.service';
import { ResponseService } from '../services/response.service';
import { RHCServiceService } from '../services/RHC-service.service';
import { ThqdepartmentService } from '../services/thqdepartment.service';
import { DivisionService } from '../services/division.service';
import { DivisionalHospitalService } from '../services/divisional-hospital.service';
declare var $: any;

@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.css']
})
export class ResponsesComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('departments') departments: ElementRef;
  @Output() headerTitle = new EventEmitter<string>();
  IsActive: boolean = true;
  isAdmin: boolean = false;
  // DHQDeptData: Array<any> = new Array();
  // THQDeptData: Array<any> = new Array();
  // RHCDeptData: Array<any> = new Array();
  DHDeptData: Array<any> = new Array();
  ResponseData: Array<any> = new Array();
  selectedResponseData: ResponseModel = new ResponseModel();
  showselectedResponse: boolean = false;
  Reason: string;
  depId: any;
  // ReferralStatusId:any
  statusData: any
  ReasonData: ResponseModel = new ResponseModel();
  CreateResponse: CreateReasonModel = new CreateReasonModel();
  public StatusId: number;
  public DepartmentId: number;
  EditResponse: UpdateReasonModel = new UpdateReasonModel();
  reasonsModel: Array<ResponseModel> = new Array();
  updateReasonModel: UpdateReasonModel = new UpdateReasonModel();
  p: boolean = null
  statusdefModel: Array<any> = new Array();
  selectdepartmentslist: Array<any> = new Array();
  checkedList: any[];
  currentSelected: any[];
  showDropDown = false;
  private closeDropdownTimer: any;

  constructor(private DhqdepartmentsService: DhqdepartmentsService,private division:DivisionalHospitalService, private rhcservice: RHCServiceService, private thqservice: ThqdepartmentService, private ResponseService: ResponseService, private status: AppointmentStatusService, private router: Router, private Toastr: ToastrService) {
    this.checkedList = []; this.currentSelected = []
  }

  ngOnInit() {
    this.IsActive = true;
    this.headerTitle.emit("Responses");
    this.isAdmin = true;
    this.division.AllDHDepartments().subscribe(data => {
      this.DHDeptData = data;
      // console.log(this.DHQDeptData)
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
    // this.rhcservice.AllRHCDepartments().subscribe(data => {
    //   this.RHCDeptData = data;
    //   // console.log(this.DHQDeptData)
    // },
    //   error => {
    //     this.Toastr.error("Something went on the server side !!!");
    //     // console.log(error);
    //   });
    // this.thqservice.AllTHQDepartments().subscribe(data => {
    //   this.THQDeptData = data;
    //   // console.log(this.DHQDeptData)
    // },
    //   error => {
    //     this.Toastr.error("Something went on the server side !!!");
    //     // console.log(error);
    //   });


    // this.isAdmin = true;
    //   this.reasonsModel = [];
    //   this.ResponseService.getAllResponses().subscribe(data=>{
    //     this.statusdefModel = data;
    //     var ActiveStatusModel = [];
    //     this.statusdefModel.forEach(function (value) {
    //     if(value.IsActive==true)
    //     {
    //       ActiveStatusModel.push(value);
    //     }
    //  },
    //  this.reasonsModel=ActiveStatusModel

    //  );    console.log(this.reasonsModel)
    // },)
    //get Responses
    // this.ResponseService.getAllResponses().subscribe(data=>{
    //   this.ResponseData = data;
    //   console.log(this.ResponseData)
    // },
    // error=>{
    //   this.Toastr.error("Something went on the server side !!!");
    //   console.log(error);
    // });

    //get referralStatus
    this.status.getAppointmentStatusData().subscribe(data => {
      this.statusData = data;
      // console.log(this.statusData)
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });

    //get Reasons
    this.reasonsModel = [];
    this.status.getAppointmentStatusReason().subscribe(data => {
      this.reasonsModel = data;
      // console.log(this.reasonsModel)
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  selectDepartmentForResponse(depId: any) {
    this.DepartmentId = parseInt(depId)
    // console.log(this.DepartmentId)
  }
  selectReferralStatusForResponse(ReferralStatusId: any) {
    this.StatusId = parseInt(ReferralStatusId)
    // console.log(this.StatusId)
  }

  selectedResponse(data) {
    this.selectedResponseData = data;
    this.showselectedResponse = true;
  }
  create() {
    this.CreateResponse.Reason = this.CreateResponse.Reason.trim()
    this.ResponseService.CreateNewResponse(this.CreateResponse).subscribe((resp) => {
      if (resp == null || resp == 0) {
        this.Toastr.error("Response already exist");
      } else {
        this.Toastr.success("Response created successfully");
        this.ngOnInit();

      }
     
      // this.selectedValuesMap[''] = { selected: [], checked: [] };
      this.CreateResponse.DepartmentId = [];
      this.currentSelected = [];
      this.searchInput.nativeElement.value = '';
      this.departments.nativeElement.value = '';

    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  UpdateResponse(response: any) {
    this.EditResponse = response
    // console.log(this.EditResponse)
  }
  selectStatusForUpdatingReason(data) {
    this.EditResponse.statusid = parseInt(data);
    // console.log(this.EditResponse.statusid)
  }
  // selectDepartmentForUpdatingReason(data) {
  //   this.EditResponse.departmentid = parseInt(data);
  //   // console.log('array',this.EditResponse.departmentid)
  // }

  EditReason() {
    $("#EditDHqDeptModal").modal("hide");
    // console.log("Before: "+this.selectedDHQ.DHQId);
    this.EditResponse.ReasonId = this.EditResponse.ReasonId;
    this.EditResponse.Reason = this.EditResponse.Reason;
    this.EditResponse.statusid = this.EditResponse.statusid;
    // this.EditResponse.departmentid = this.EditResponse.departmentid;
    this.EditResponse.IsActive = this.EditResponse.IsActive;

    // console.log(this.EditResponse);
    this.ResponseService.EditResponse(this.EditResponse).subscribe(resp => {
      this.Toastr.success('Response Updated Successfully ');
      this.ngOnInit();
      // console.log(resp);
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });

  }

  // getSelectedValue(status: Boolean, name: String, id: any) {
  //   if (status) {
  //     this.checkedList.push(id);
  //     this.currentSelected.push(name);
  //   } else {
  //     var index = this.checkedList.indexOf(id);
  //     var indexes = this.currentSelected.indexOf(name);
  //     this.checkedList.splice(indexes, 1);
  //   }
  //   console.log(id, this.checkedList)
  //   this.CreateResponse.DepartmentId = this.checkedList;
  // }
  getSelectedValue(status: Boolean,name:String, id: any) {
    // console.log(id)
    const idIndex = this.checkedList.indexOf(id);
    if (status) {
      if (idIndex === -1) {
        this.checkedList.push(id);
        this.currentSelected.push(name);
      }
    } else {
      if (idIndex !== -1 ) {
        this.checkedList.splice(idIndex, 1);
      }
    }
    this.CreateResponse.DepartmentId = this.checkedList;
  }
  startDropdownCloseTimer() {
    this.closeDropdownTimer = setTimeout(() => {
        this.showDropDown = false;
    }, 2000); // Set the delay in milliseconds (2 seconds in this example)
}

cancelDropdownCloseTimer() {
    clearTimeout(this.closeDropdownTimer);
}

  //code for three all departments of dhq,rhc,thq
  // selectedValuesMap: { [key: string]: { selected: string[], checked: any[] } } = {};
  // getSelectedValue(status: boolean, name: string, id: any, departmentType: string) {
  //   // Check if the department type is already in the map
  //   if (!this.selectedValuesMap[departmentType]) {
  //     // If not, initialize the arrays for this department type
  //     this.selectedValuesMap[departmentType] = { selected: [], checked: [] };
  //     // this.CreateResponse.DepartmentId = [];
  //   }
  //   const selectedValues = this.selectedValuesMap[departmentType];
  //   if (status) {
  //     selectedValues.checked.push(id);
  //     selectedValues.selected.push(name);
  //     this.CreateResponse.DepartmentId.push({ name: departmentType,  id: [] });
  //     const department = this.CreateResponse.DepartmentId.find(dep => dep.name === departmentType);
  //   department.id.push(id);

  //   } else {
  //     const index = selectedValues.checked.indexOf(id);
  //     selectedValues.checked.splice(index, 1);
  //     selectedValues.selected.splice(index, 1);

  //     const department = this.CreateResponse.DepartmentId.find(dep => dep.name === departmentType);
  //     console.log(department);
  //     if (department) {
  //       const idIndex = department.id.indexOf(id);

  //       if (idIndex !== -1) {
  //         department.id.splice(idIndex, 1);
  //       }

  //       // If the department type has no more ids, remove the department type from the array
  //       if (department.id.length === 0) {
  //         const depIndex = this.CreateResponse.DepartmentId.indexOf(department);
  //         if (depIndex !== -1) {
  //           this.CreateResponse.DepartmentId.splice(depIndex, 1);
  //         }
  //       }
  //     }}
  //     console.log(this.CreateResponse);
  //   }

  }

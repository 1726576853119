import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { RHCServiceService } from '../services/RHC-service.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RHCDeptModel } from '../Model/RHCDepartmentModel';
declare var $: any;

@Component({
  selector: 'app-rhcdepartments',
  templateUrl: './rhcdepartments.component.html',
  styleUrls: ['./rhcdepartments.component.css']
})
export class RHCDepartmentsComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  RHCDeptData: Array<any>= new Array();
  selectedRHCDepartmentData: RHCDeptModel = new RHCDeptModel();
  Name: string;
  Code: string;
  IsActive: boolean = true;
  isAdmin : boolean = false;
  showselectedRHCDept: boolean = false;
  
  constructor(private rhcservice: RHCServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.IsActive = true;
    this.headerTitle.emit("RHC Departments");
    this.isAdmin = true;
    this.rhcservice.AllRHCDepartments().subscribe(data=>{
      this.RHCDeptData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      console.log(error);
    });
  }
  selectedRHCDept(data){
    this.selectedRHCDepartmentData = data;
    this.showselectedRHCDept = true;
  } 

  create(){
    console.log("create thq", this.Name, this.Code, this.IsActive)
    this.rhcservice.AddNewRHCDepartment(this.Name,this.Code,this.IsActive).subscribe((resp)=>{
      this.Toastr.success('RHC Department Created Successfully ');
      this.ngOnInit();
      this.Name = '';
      this.Code = '';
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  Update(){
    this.rhcservice.UpdateRHCDepartment(this.selectedRHCDepartmentData).subscribe(resp=>{
      this.Toastr.success('RHC Department Updated Successfully ');
      $("#EditDHqDeptModal").modal("hide");
      this.ngOnInit();
    },err=>{
    });
  } 
}

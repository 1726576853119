import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DistrictsService } from '../services/districts.service';
import {edit, DistrictModel} from '../Model/DistrictModel'
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { DivisionService } from '../services/division.service';
declare var $: any;

@Component({
  selector: 'app-districts',
  templateUrl: './districts.component.html',
  styleUrls: ['./districts.component.css']
})
export class DistrictsComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  DistrictsData: Array<any>= new Array();
  DistrictName: string;
  DistrictCode: string;
  IsActive:any
  isAdmin : boolean = false;
  CreateDistrict: DistrictModel = new DistrictModel();
  SelectedDistrict : DistrictModel = new DistrictModel();
editmodel:edit=new edit()
DistrictId:number
checkedList: any[];
currentSelected:any[];
DivisionData: Array<any> = new Array();
DivisionId:number;
selectedDivision: any;

  constructor(private DistrictsService: DistrictsService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService, private DivisionService: DivisionService) { }

  ngOnInit() {
    this.CreateDistrict.IsActive = true;
    this.headerTitle.emit("Districts");
    this.isAdmin = true;
    this.DistrictsService.getAllDistrictData().subscribe(data=>{
      this.DistrictsData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });
    this.DivisionService.getAllDivisionData().subscribe(data => {
      this.DivisionData = data;
      // console.log(this.DHQDeptData)
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

 SelectedDistrictDetail(data){
    this.editmodel = data;
    // this.DistrictId=data.Id

    // this.DistrictName=data.DistrictName
    // // this.DistrictCode=data.DistrictCode
    // this.IsActive=data.IsActive

    // console.log(this.DistrictId);
  } 

  create(){
    this.CreateDistrict.DistrictName=this.CreateDistrict.DistrictName.trim();
    this.DistrictsService.AddNewDistrict(this.CreateDistrict).subscribe((resp)=>{
      if(resp == 'District already exist'){
        this.Toastr.error(resp);
      }else{
        this.Toastr.success(resp);
      }
      this.ngOnInit();
      this.CreateDistrict.DistrictName = '';
      this.CreateDistrict.DistrictCode = '';
      this.CreateDistrict.DivisionId = 0;
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  Update(){

    this.DistrictsService.UpdateDistrict(this.editmodel).subscribe(resp=>{
      this.Toastr.success('District Updated Successfully ');
      $("#editDistrictModal").modal("hide");
      this.ngOnInit();
      // console.log(resp);
    },err=>{
      // console.log(err);
    });

  } 
  selectDivision(Id:any){
    this.DivisionId = parseInt(Id)
  }

}

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ExportService } from '../services/export.service';
import { Router } from '@angular/router';
import { GenericServiceService } from '../services/generic-service.service';
import { DivisionService } from '../services/division.service';
import { DistrictModel } from '../Model/DistrictModel';
import { CreateDivision } from '../Model/DivisionModel';
import { DH, DH1 } from '../Model/DHModel';
import { DivisionalHospitalService } from '../services/divisional-hospital.service';
import { UpdateDHModel } from '../Model/DHDetailModel';
declare var $: any;

@Component({
  selector: 'app-dh-hospital',
  templateUrl: './dh-hospital.component.html',
  styleUrls: ['./dh-hospital.component.css']
})
export class DHHospitalComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  selectedDHDetails: boolean= false;
  isAdmin : boolean = false;
  districtModel: Array<DistrictModel> = new Array();
  divisionModel: Array<CreateDivision> = new Array()
  divisiondefModel: Array<any> = new Array();
  DHList: Array<DH> = new Array();
  public DHName : string;
  public DHContact : string;
  public DHAddress : string;
  public District : string;
  public Locality1: number;
  selectedDH: DH = new DH();
  dp:boolean=true
  AppointmentsDetails: Array<any>= new Array();
  apdate:boolean=false
  DHUser: DH = new DH();
  selectedDHDATA: DH1 = new DH1();
  showDhdata:boolean= false;
  showPatientDetail:boolean= false;
  DHUserPatientsList : Array<DH>= new Array();
  updateDHModel : UpdateDHModel = new UpdateDHModel();
  selectedDivisionId: number = 0;
  districtdefModel: Array<any> = new Array();

  constructor(private division: DivisionService,private dhservice:DivisionalHospitalService, private genericService: GenericServiceService, private router: Router,private exportService: ExportService,private Toastr: ToastrService) { }

  ngOnInit() {
    this.selectedDHDetails = false;
    this.headerTitle.emit("Division Hospital");
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'RHC' || localStorage.getItem("role") == 'SuperAdmin') {
      this.isAdmin = true;
      this.districtModel = [];
      this.divisionModel = [];

      this.division.getAllDivisionData().subscribe(data => {
        this.divisiondefModel = data;
        var ActiveDivisionModel = [];
        this.divisiondefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveDivisionModel.push(value);
          }
        },
          this.divisionModel = ActiveDivisionModel
        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });

      this.DHList = [];
      this.dhservice.getAllDH().subscribe(data => {
        this.DHList = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });
    }
  }

  createDH(){
    this.dhservice.CreateNewDH(this.DHName,this.DHContact,this.DHAddress,this.Locality1).subscribe((resp)=>{
      this.Toastr.success('Divisional Hospital Created Successfully ');
      $("#createDHQModal").modal("hide");
      this.ngOnInit();
      this.DHName='';
      this.DHContact='';
      this.DHAddress='';
      },
      (err)=>{
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  CreateCovidDHUsers(){
    this.dhservice.CreateCovidDHUser().subscribe((resp)=>{
      this.Toastr.success('Divisional Hospital User Updated Successfully ');
      this.ngOnInit();
      },
      (err)=>{
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  selectDistricForDH(districtId: any){
    this.Locality1 = parseInt(districtId);
  }

  showSelectedDistrictDH (districtId:any){
    this.DHList = [];
    this.dhservice.getSelectedDistrictDH(districtId).subscribe(data=>{
      this.DHList = data;  
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
    });
  }

  selectedLab(lab : DH){
    this.selectedDH = lab;
    this.selectedDHDetails = true;
    this.dp=false
    // console.log(this.selectedDH)
    
  }
  SelectedReferral(data){
    this.AppointmentsDetails = data.Appointments;
    this.apdate=true
  }
  editselectedDHUser(DHuser: DH){
    this.DHUser = DHuser;
  }

  SelectedDhdata(dhdata :DH1)
  {
    this.selectedDHDATA = dhdata;
    this.showDhdata = true
    this.selectedDHDetails=false
  }
  refreshddata(){
    $('#dataofslected').on('hidden.bs.modal',
    function () {
     $('#dataofslected form')[0].reset();
     });
  }

  selectedDHUserPatients(DHUserPatients){
    this.showPatientDetail = true;
    this.dhservice.getSelectedDHUserPatients(DHUserPatients.DHLoginId).subscribe(data=>{
      this.DHUserPatientsList = data; 
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
    });
  }

  selectDistricForUpdatingDH(tehsilId: any){
    this.Locality1 =  parseInt(tehsilId);
  }

  EditLab(){
    $("#editLabModal").modal("hide");
    this.updateDHModel.DivisionalHospitalId = this.selectedDH.DivisionalHospitalId;
    this.updateDHModel.Name = this.selectedDH.DivisionalHospitalName;
    this.updateDHModel.Address = this.selectedDH.Address;
    this.updateDHModel.Contact = this.selectedDH.Contact;
    this.updateDHModel.DistrictId = this.selectedDH.DistrictId;
    this.updateDHModel.IsActive = this.selectedDH.IsActive;
    this.dhservice.UpdateDH(this.updateDHModel).subscribe(resp=>{
      this.Toastr.success('Divisional Hospital Updated Successfully ');
      this.ngOnInit();
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
    });
  } 

  checkboxClicked() {
    this.DHUser.ScheduledDays.filter(x => x.IsScheduled).map(x => {
    //  this.DHQUser.ScheduledDays[0].ScheduledDayId = x.ScheduledDayId;
    //  this.DHQUser.ScheduledDays[0].IsScheduled = x.IsScheduled;
    })
  }

  EditDHUser(){
    this.dhservice.UpdateDHUser(this.DHUser.DivisionalHospitalUserId,this.DHUser.Name,this.DHUser.IsActive,this.DHUser.ScheduledDays).subscribe((resp)=>{
      this.Toastr.success('Divisional Hospital User Updated Successfully ');
      $("#editDHQUser").modal("hide");
      this.ngOnInit();
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  export(reportType: number) {
    if(reportType == 1){
      this.exportService.exportExcel(this.DHList, 'Divisional Hospital');
    }
    else if(reportType == 2){
      this.exportService.exportExcel(this.DHUserPatientsList, 'Divisional Hospital User Patients');
    }
  }

  showSelectedDivision(divisionId: any) {
    this.selectedDivisionId = divisionId;
    this.genericService.getDistrictData(divisionId).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.districtModel = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GenericServiceService } from '../services/generic-service.service';
import { Router } from '@angular/router';
import { DistrictModel } from '../Model/DistrictModel';
import { TehsilModel } from '../Model/TehsilModel';
import { ExportService } from '../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { forEach } from '@angular/router/src/utils/collection';
import { THQ, THQ1 } from '../Model/THQModel';
import { UpdateTHQModel } from '../Model/THQDetailModel';
import { THQService } from '../services/thq.service';
import { CreateDivision } from '../Model/DivisionModel';
import { DivisionService } from '../services/division.service';
declare var $: any;

@Component({
  selector: 'app-thq',
  templateUrl: './thq.component.html',
  styleUrls: ['./thq.component.css']
})
export class THQComponent implements OnInit {

  THQList: Array<THQ> = new Array();
  THQUserPatientsList: Array<THQ> = new Array();
  districtModel: Array<DistrictModel> = new Array();
  divisionModel: Array<CreateDivision> = new Array()
  teshilModel: Array<TehsilModel> = new Array();
  isAdmin: boolean = false;
  public THQName: string;
  p: boolean = null
  public THQContact: string;
  public THQAddress: string;
  public THQDistrict: string;
  public Locality1: number;
  selectedDistrict: number;
  showPatient: boolean = false;
  updateTHQModel: UpdateTHQModel = new UpdateTHQModel();
  @Output() headerTitle = new EventEmitter<string>();
  selectedTHQ: THQ = new THQ();
  DHQUserPatientsListbyref: any;
  selectedTHQDATA: THQ1 = new THQ1();
  THQUser: THQ = new THQ();
  selectedTHQDetails: boolean = false;
  showPatientDetail: boolean = false;
  showThqdata: boolean = false;
  dp: boolean = true
  AppointmentsDetails: Array<any> = new Array();
  apdate: boolean = false
  districtdefModel: Array<any> = new Array();
  divisiondefModel: Array<any> = new Array();
  selectedDivisionId: number = 0;
  tr: boolean = true;
  selectedRHC: THQ = new THQ();
  showselectedTHQ: boolean = false;

  constructor(private THQService: THQService, private division: DivisionService, private genericService: GenericServiceService, private router: Router, private exportService: ExportService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.selectedTHQDetails = false;
    this.headerTitle.emit("THQ");
    // Get District
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'RHC' || localStorage.getItem("role") == 'SuperAdmin') {
      this.isAdmin = true;
      this.districtModel = [];
      this.divisionModel = [];

      this.division.getAllDivisionData().subscribe(data => {
        this.divisiondefModel = data;
        var ActiveDivisionModel = [];
        this.divisiondefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveDivisionModel.push(value);
          }
        },
          this.divisionModel = ActiveDivisionModel

        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
      this.THQList = [];
      this.THQService.getAllTHQ().subscribe(data => {
        this.THQList = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
    }
  }
  createTHQ() {
    this.THQService.CreateNewTHQ(this.THQName, this.THQContact, this.THQAddress, this.Locality1).subscribe((resp) => {
      // Store access token, UserId and Role in local storage
      this.Toastr.success('THQ Created Successfully ');
      $("#createTHQModal").modal("hide");
      this.ngOnInit();
      this.THQName = '';
      this.THQContact = '';
      this.THQAddress = '';
    },
      (err) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
  }


  // code added for user creation for covid department for all district 'this.DHQName,this.DHQContact,this.DHQAddress,this.Locality1
  CreateCovidTHQUsers() {
    this.THQService.CreateCovidTHQUser().subscribe((resp) => {
      this.Toastr.success('DHQ Updated Successfully ');
      // $("#createDHQModal").modal("hide");
      this.ngOnInit();
    },
      (err) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
  }

  selectDistricForTHQ(districtId: any) {
    this.Locality1 = parseInt(districtId);
  }
  openEditingField() {
    this.tr = !this.tr
  }
  showSelectedDivision(divisionId: any) {
    this.selectedDivisionId = divisionId;
    this.genericService.getDistrictData(divisionId).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
          
        }
      },
        this.districtModel = ActiveDistrictModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  showSelectedDistrictTHQ(divisionId: any) {
    this.THQList = [];
    this.selectedDivisionId = divisionId;
    this.THQService.getSelectedDistrictTHQ(divisionId).subscribe(data => {
      this.THQList = data;
      // this.DHQList = this.DHQList.slice(0,-2); 
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  selectedLab(lab: THQ) {
    this.selectedTHQ = lab;
    this.selectedTHQDetails = true;
    this.dp = false
    // console.log(lab.DHQUsers[4].Name)

  }
  selectedTHQDetail(THQ: any,) {
    this.selectedTHQ = THQ;
    // console.log("data", this.selectedRHC);

    this.showselectedTHQ = true;
  }
  SelectedReferral(data) {
    this.AppointmentsDetails = data.Appointments;
    this.apdate = true
  }
  editselectedTHQUser(THQuser: THQ) {
    this.THQUser = THQuser;
    // console.log(this.DHQUser)
  }
  UpdateSelectedDistricForTHQCreation(data) {
    this.selectedTHQ.DistrictId = parseInt(data);
  }

  SelectedThqdata(thqdata: THQ1) {
    this.selectedTHQDATA = thqdata;
    this.showThqdata = true
    this.selectedTHQDetails = false
  }
  refreshddata() {
    $('#dataofslected').on('hidden.bs.modal',
      function () {
        $('#dataofslected form')[0].reset();
      });
  }

  selectedTHQUserPatients(THQUserPatients) {
    this.showPatientDetail = true;
    // this.DHQUserPatientsList = [];
    this.THQService.getSelectedTHQUserPatients(THQUserPatients.THQLoginId).subscribe(data => {
      this.THQUserPatientsList = data;

    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  selectDistricForUpdatingTHQ(tehsilId: any) {
    this.Locality1 = parseInt(tehsilId);
    // console.log(this.Locality1)
  }

  EditLab() {
    $("#editLabModal").modal("hide");
    // console.log("Before: "+this.selectedDHQ.DHQId);
    this.updateTHQModel.THQId = this.selectedTHQ.Id;
    this.updateTHQModel.Name = this.selectedTHQ.Name;
    this.updateTHQModel.Contact = this.selectedTHQ.Contact;
    this.updateTHQModel.DistrictId = this.selectedTHQ.DistrictId;
    this.updateTHQModel.IsActive = this.selectedTHQ.IsActive;

    // console.log(this.updateDHQModel);
    this.THQService.UpdateTHQ(this.updateTHQModel).subscribe(resp => {
      this.Toastr.success('DHQ Updated Successfully ');
      this.ngOnInit();
      // console.log(resp);
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });

  }

  // checkboxClicked() {
  //   this.THQUser.ScheduledDays.filter(x => x.IsScheduled).map(x => {
  //     // console.log(x.ScheduledDayId);

  //     //  this.DHQUser.ScheduledDays[0].ScheduledDayId = x.ScheduledDayId;
  //     //  this.DHQUser.ScheduledDays[0].IsScheduled = x.IsScheduled;
  //   })
  // }

  // EditTHQUser() {

  //   // console.log(this.DHQUser.DHQUserId, this.DHQUser.Name,this.DHQUser.ScheduledDays);
  //   this.THQService.UpdateTHQUser(this.THQUser.THQUserId, this.THQUser.Name, this.THQUser.IsActive, this.THQUser.ScheduledDays).subscribe((resp) => {
  //     // Store access token, UserId and Role in local storage
  //     this.Toastr.success('THQ User Updated Successfully ');
  //     $("#editDHQUser").modal("hide");
  //     this.ngOnInit();

  //   },
  //     (error) => {
  //       this.Toastr.error("Something went on the server side !!!");
  //       // console.log(error);
  //     });
  // }

  export(reportType: number) {
    if (reportType == 1) {
      this.exportService.exportExcel(this.THQList, 'DHQs');
    }
    else if (reportType == 2) {
      this.exportService.exportExcel(this.THQUserPatientsList, 'DHQUser Patients');
    }

  }
}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { PatientStatus } from '../Model/PatientStatusModel';
import { GenericServiceService } from '../services/generic-service.service';
import { PatientStatusService } from '../services/patient-status.service';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-patient-status',
  templateUrl: './patient-status.component.html',
  styleUrls: ['./patient-status.component.css']
})
export class PatientStatusComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  patientstatusData: Array<PatientStatus>= new Array();
  isAdmin : boolean = false;
  showselectedPatientStatus: boolean = false;
  public  PatientStatusName: string;
  selectedPatientStatus : PatientStatus = new PatientStatus();

  constructor(private patientstatusService: PatientStatusService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.headerTitle.emit("Patient Status");
    this.isAdmin = true;
    this.patientstatusService.getPatientStatusData().subscribe(data=>{
      this.patientstatusData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });
  }


  selectedPatientStatusDetail(patientstatus : PatientStatus){
    this.selectedPatientStatus = patientstatus;
    this.showselectedPatientStatus = true;
  }

  createPatientStatus(){
    this.PatientStatusName = this.PatientStatusName.trim()
    this.patientstatusService.CreateNewPatientStatus(this.PatientStatusName).subscribe((resp)=>{
      if(resp='This status already exist'){
        this.Toastr.error(resp);
      }else{ this.Toastr.success(resp);}
      this.ngOnInit();
      this.PatientStatusName='';
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }

  EditPatientStatus(){

    this.patientstatusService.UpdatePatientStatus(this.selectedPatientStatus.PatientStatusId,this.selectedPatientStatus.Status,this.selectedPatientStatus.IsActive ).subscribe(resp=>{
      this.Toastr.success('Patient Status Updated Successfully ');
      $("#editPatientStatusModal").modal("hide");
      this.ngOnInit();
      // console.log(resp);
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
      // console.log(error);
    });

  }

}

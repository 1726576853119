import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RHCServiceService {
 // baseUrl:string="http://patientrefferals-api-dev.asd.org.pk";
  constructor(private _http:HttpClient,private router:Router) { }

  getehsilData():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Admin/GetAllTehsils";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getAllRHC():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/GetAllRHCs?districtId="+ 0;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  getRHCByDictrict(id:number):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/GetAllRHCs?districtId="+ id;
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  CreateNewRHC(RHCName:string, RHCPhone:string,RHCAddress:string,DistrictId :number):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/CreateRHC";
    let data = {Name:RHCName, Contact:RHCPhone,Address:RHCAddress,DistrictId : DistrictId}
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateRHC(data):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/UpdateRHC";
    // let data = {RHCId:Id, Name:name, Contact:contact, Address:address , DistrictId:DistrictId,IsActive:IsActive}
    return this._http.post(FullUrl,data,requestOptions);
  }

  AllRHCDepartments():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/RHC/GetRHCDepartments";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }


  AddNewRHCDepartment(Name:string, Code:string,IsActive:any):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/CreateRHCDepartment";
    let data = {DepartmentName:Name, TypeCode: Code,IsActive: IsActive}
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateRHCDepartment(data):Observable<any>{

    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/UpdateRHCDepartment";
    return this._http.post(FullUrl,data,requestOptions);
  }
  UpdateRHCUser(RHCUserId:number,Name:string,IsActive:boolean,schedueledetails: any):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/RHC/UpdateRHCUsers";
    let data = {RHCUserId:RHCUserId, RHCUserName:Name ,IsActive:IsActive, ScheduledDays:schedueledetails}
    return this._http.post(FullUrl,data,requestOptions);
  }

}
